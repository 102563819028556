// ForgotPassword.tsx
import { useState } from 'react'
import { Modal, Button, Input, Form, message } from 'antd'
import { forgotpassword } from '@/app/auth/actions'

const ForgotPasswordModal = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')

    const showModal = () => setIsModalVisible(true)
    const handleCancel = () => setIsModalVisible(false)

    const handleResetPassword = async () => {
        setLoading(true)
        await forgotpassword(email)
        setLoading(false)
        message.info('reset password sent to your email')
    }

    return (
        <>
            <Button type="link" onClick={showModal}>
                Forgot Password?
            </Button>
            <Modal
                title="Forgot Password"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form onFinish={handleResetPassword}>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                                type: 'email',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            block
                        >
                            Reset Password
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default ForgotPasswordModal
