'use client'
import { login, signup } from './actions'
import { Button, Checkbox, Form, Input, message } from 'antd'
import { useRouter } from 'next/navigation'
import ForgotPasswordModal from '@/app/components/auth/ForgotPassword'
import './auth.scss'

const { Item } = Form
const { Password } = Input

const AuthRoute = () => {
    const router = useRouter()

    const handleLogin = async (values: any) => {
        // values contain email and password
        const { email, password } = values
        const response = await login({ email, password })
        console.log(response)
        if (response?.message) {
            message.error(response.message)
        }
    }
    return (
        <div className="authRouteContainer">
            <Form onFinish={handleLogin}>
                <Item
                    label="Email"
                    name="email"
                    rules={[
                        { required: true, message: 'Please input your email!' },
                    ]}
                >
                    <Input type="email" />
                </Item>
                <Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Password type="password" />
                </Item>
                <Item>
                    <Button type="primary" htmlType="submit">
                        Log in
                    </Button>
                    <Button
                        style={{ marginLeft: 8 }}
                        onClick={() => {
                            console.log('to sign up')
                            router.push('/auth/signup')
                        }}
                    >
                        Sign up
                    </Button>
                </Item>
                <Item>
                    <ForgotPasswordModal />
                </Item>
            </Form>
        </div>
    )
}

export default AuthRoute
